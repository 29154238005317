<template>
  <div class="main">
    <CorrectionItemDetail :title="title"
      :cardData="cardData"
      :argumentsCN="argumentsCN"
      :permissions="policyDetailPermissions"></CorrectionItemDetail>
    <div class="bottm-btn">
      <el-button size="mini"
        @click="goBack"
        class="btn btn1">返回</el-button>
      <el-button size="mini"
        @click="viewFile"
        class="btn btn2">查看电子批单</el-button>
    </div>
  </div>
</template>

<script>
import CorrectionItemDetail from "../../components/CorrectionItemDetail/index.vue";
import { number2text } from "../../utils/index";
import { modificationDetails } from "@/api/policy";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  name: "CorrectionDetails",
  components: { CorrectionItemDetail },
  data() {
    return {
      url: '',
      title: ["保险概要", "变更内容", "批文"],
      cardData: [
        {
          insuranceTypeName: "",
          policyHolder: "",
          totalPremium: ``,
          policyNo: "",
          duringTime: "",
        },
        {
          approvalTypeName: "",
          riderNumber: "",
          riderCreateTime: "",
          effectiveTime: "",
        },
        {
          approval: "",
        },
      ],
      argumentsCN: [
        {
          insuranceTypeName: "险种名称",
          policyHolder: "投保人名称",
          totalPremium: "保费",
          policyNo: "保单号",
          duringTime: "保险期限",
        },
        {
          approvalTypeName: "批改类型",
          riderNumber: "批改单号",
          riderCreateTime: "申请时间",
          effectiveTime: "生效时间",
        },
        { approval: "" },
      ],
      policyDetailPermissions: {
        //   agentId: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "agentId"),
        //   // "desc": "代理人",
        //   applicantMode: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "applicantMode"),
        //   // "desc": "投保方式",
        //   lastPolicyNo: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "lastPolicyNo"),
        //   // "desc": "上一年保单号",
        //   endDate: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "endDate"),
        //   // "desc": "到期时间",
        //   city: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "city"),
        //   // "desc": "所属地区",
        //   name: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "name"),
        //   // "desc": "保单号",
        //   province: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "province"),
        //   // "desc": "所属省份",
        //   applicantType: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "applicantType"),
        //   // "desc": "投保类型",
        //   acceptCompanyList: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "acceptCompanyList"),
        //   // "desc": "承保公司",
        //   extensionClause: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "extensionClause"),
        //   // "desc": "免费扩展条款",
        //   specialAgreement: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "specialAgreement"),
        //   // "desc": "特别约定",
        //   applicantStore: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "applicantStore"),
        //   // "desc": "投保门店",
        //   absoluteDeductible3: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "absoluteDeductible3"),
        //   // "desc": "未扩展不计免赔",
        //   absoluteDeductible2: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "absoluteDeductible2"),
        //   // "desc": "扩展不计免赔2",
        //   salesmanId: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "salesmanId"),
        //   // "desc": "业务员",
        //   agentNo: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "agentNo"),
        //   // "desc": "代理人编码",
        //   beneficiaryList: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "beneficiaryList"),
        //   // "desc": "受益人",
        //   policyHolderList: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "policyHolderList"),
        //   // "desc": "投保人",
        //   totalPremium: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "totalPremium"),
        //   // "desc": "总保费",
        //   ep: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "ep"),
        //   // "desc": "电子保单",
        //   projectRemark: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "projectRemark"),
        //   // "desc": "项目备注",
        //   policyPlanList: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "policyPlanList"),
        //   // "desc": "保障计划",
        //   policySubjectList: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "policySubjectList"),
        //   // "desc": "标的信息",
        //   absoluteDeductible: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "absoluteDeductible"),
        //   // "desc": "扩展不计免赔1",
        //   policyInsuredList: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "policyInsuredList"),
        //   // "desc": "被保险人",
        //   projectName: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "projectName"),
        //   // "desc": "项目名称",
        //   startDate: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "startDate"),
        //   // "desc": "起保时间",
        //   enquiryNo: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "enquiryNo"),
        //   // "desc": "询价单号",

        //   //批文
        //   approval: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "approval"),
        //   // "desc": "其他类型"
        //   orderType: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "orderType"),
        //   // "desc": "户名"
        //   accountName: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "accountName"),
        //   // "desc": "开户行"
        //   accountBlank: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "accountBlank"),
        //   // 银行账户
        //   accountNumber: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "accountNumber"),
        //   //设领款人证件类型
        //   idType: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "idType"),
        //   //证件号码
        //   idNumber: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "idNumber"),
        //   //联系电话
        //   phone: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "phone"),
        //   recorder: dataPermissions("M25_${this.$route.meta.policyType}-Edit", "recorder"),
        insuranceTypeName: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "insuranceTypeName"),
        policyHolder: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "policyHolder"),
        totalPremium: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "totalPremium"),
        policyNo: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "policyNo"),
        startDate: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "startDate"),
        endDate: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "endDate"),
        approvalTypeName: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "approvalTypeName"),
        riderNumber: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "riderNumber"),
        riderCreateTime: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "riderCreateTime"),
        effectiveTime: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "effectiveTime"),
        approval: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "approval"),
        pr: dataPermissions(`M25_${ this.$route.meta.policyType }-Edit`, "pr"),
      },
    };
  },
  async mounted() {
    let result = await modificationDetails({
      riderId: this.$route.params.riderId,
    });
    let {
      insuranceTypeName,
      policyHolder,
      totalPremium,
      policyNo,
      startDate,
      endDate,
      approvalTypeName,
      riderNumber,
      riderCreateTime,
      effectiveTime,
      approval,
      pr
    } = result.data;
    this.url = pr.url;
    this.cardData = [
      {
        insuranceTypeName,
        policyHolder,
        totalPremium: `人民币 ${ number2text(totalPremium) }, ¥${ totalPremium }`,
        policyNo,
        duringTime: `自${ startDate }起 至 ${ endDate }止`,
      },
      {
        approvalTypeName,
        riderNumber,
        riderCreateTime,
        effectiveTime,
      },
      { approval },
    ];
  },
  methods: {
    goBack() {
      //console.log(this.$route.params.data);
      this.$router.go(-1);
    },
    viewFile() {
      if (this.url) {
        window.open(this.url);
      } else {
        this.$message.error('暂无电子批单');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
}
.bottm-btn {
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  opacity: 1;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 10px;
  height: 50px;
  .btn {
    font-size: 14px;
    font-weight: 400;
    margin: 10px 10px;
  }
  .btn1 {
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #4278c9;
    color: #4278c9;
  }
  .btn2 {
    color: #ffffff;
    background: #4278c9;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
}
</style>
