<template>
  <div>
    <el-card v-if="dataType == 'object'"
      class="card-item">
      <div class="title">
        {{ title }}
      </div>
      <div class="divider"></div>
      <div class="content">
        <div v-for="(arg, index) in argumentsName"
          :key="index"
          class="content-item">
          {{ argumentsCN[arg] }}{{argumentsCN[arg]?': ':''}}{{ cardData[arg] }}
        </div>
      </div>
    </el-card>
    <template v-if="dataType == 'array'">
      <el-card v-for="(cardItem, index) in cardData"
        :key="index"
        class="card-item">
        <div class="title">
          {{ title[index] }}
        </div>
        <div class="divider"></div>
        <div class="content">
          <template v-for="(arg,i) in argumentsName[index]">
            <div v-if="permissions[arg]"
              :key="i"
              class="content-item">
              {{ argumentsCN[index][arg] }}{{argumentsCN[index][arg]?': ':''}}{{ cardItem[arg] }}
            </div>
          </template>

        </div>
      </el-card>
    </template>
  </div>
</template>

<script>
export default {
  name: "CorrectionItemDetail",
  props: {
    //Array['历史记录','保险概要']
    title: {
      type: [String, Array],
      default: "历史记录",
    },
    //Array[{name: "无",number: "20221201"},{name: "无",number: "20221201"}]
    cardData: {
      type: [Object, Array],
      default: () => {
        return {
          name: "无",
          number: "20221201",
          a: "afhahkhj",
          b: "ahfiuayfiay",
        };
      },
    },
    //Array[{name: "姓名",number: "号码"},{name: "姓名",number: "号码"}]
    argumentsCN: {
      type: [Object, Array],
      default: () => {
        return {
          name: "姓名",
          number: "号码",
          a: "速度很快就",
          b: "哈覅哦hi看",
        };
      },
    },
    permissions: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      dataType: "object",
    };
  },
  computed: {
    argumentsName() {
      if (this.cardData instanceof Array) {
        this.dataType = "array";
        return this.cardData.map((item) => {
          return Object.keys(item);
        });
      } else if (typeof this.cardData == "object") {
        this.dataType = "object";
        return Object.keys(this.cardData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .personnel_repetition {
   display: flex;
   align-items: center;
   font-size: 14px;
   color: #333333;
      padding: 8px 12px;
      margin-bottom: 24px;
      background: #CCE6FF;
      border-radius: 8px;
      &_details {
         color: #0080FF;
         text-decoration: underline;
         cursor: pointer;
      }
  }
.card-item {
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  display: column;
  margin-top: 10px;

  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 0px;

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 24px;
      background: #4278c9;
      border-radius: 20px 4px 4px 20px;
      opacity: 1;
      margin: 0 8px 0 4px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 12px 4px 0 4px;
    background-image: linear-gradient(
      to right,
      #ccc 0%,
      #ccc 50%,
      transparent 50%
    );
    background-size: 15px 1px;
    background-repeat: repeat-x;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .content-item {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 24px 0 0 18px;
      min-width: 300px;
    }
  }
}
</style>
